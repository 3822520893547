// eslint-disable-next-line no-unused-vars
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respData: null,
    respDataPartners: null,
    respDataTemplate: null,
  },
  getters: {
  },
  mutations: {
    GET(state, payload) {
      state.respData = payload
    },
    GET_PARTNERS(state, payload) {
      state.respDataPartners = payload
    },
    GET_TEMPLATE(state, payload) {
      state.respDataTemplate = payload
    },
    UPDATE_STATUS(state, payload) {
      const productIndex = state.respData.data.findIndex(p => p._id === payload.data._id)
      Object.assign(state.respData.data[productIndex], payload.data)
    },
    UPDATE_DATA_PAYPACKAGE(state, payload) {
      const productIndex = state.respData.data.findIndex(p => p._id === payload.data._id)
      Object.assign(state.respData.data[productIndex], payload.data)
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    get({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/manageProfile/get?pageSize=${item.pageSize}&currentPage=${item.currentPage}&searchQuery=${item.searchQuery}&partnerUserId=${item.partnerUserId}&expire=${item.expire}&closeExpire=${item.close_expire}&activeStatus=${item.active_status}`)
          .then(response => {
            commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    verifiedUpdate({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post(`/api/web/application/hero/manageProfile/verifiedUpdate/${item._id}`, { verified: item.value })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    updateOpen({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post(`/api/web/application/hero/manageProfile/updateOpen/${item._id}`, { openingStatus: item.value })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateStatus({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post(`/api/web/application/hero/manageProfile/updateStatus/${item._id}`, { status_hide: item.value })
          .then(response => {
            commit('UPDATE_STATUS', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getTemplatePackage({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/manageProfile/getTemplatePackage?profilePartnerId=${item}`)
          .then(response => {
            commit('GET_TEMPLATE', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    payPackage({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/application/hero/manageProfile/payPackage', {
          servicePackageTemplateId: item.servicePackageTemplateId, profilePartnerId: item.profilePartnerId, day: item.day, slip: item.img,
        })
          .then(response => {
            commit('UPDATE_DATA_PAYPACKAGE', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    cancelPackage({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/application/hero/manageProfile/cancelPackage', { profilePartnerId: item.profilePartnerId })
          .then(response => {
            commit('UPDATE_DATA_PAYPACKAGE', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getPartners({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/api/web/application/hero/manageProfile/getPartners')
          .then(response => {
            commit('GET_PARTNERS', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    loginProfile({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/application/hero/manageProfile/loginProfile', item)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
